import * as React from 'react';
import * as Styled from './homePageContentStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const HomePageContent = ({ section }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.Header2>{children}</Styled.Header2>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };
  return (
        <Styled.MigrationPanelInnerContainer>
              {documentToReactComponents(
                JSON.parse(section?.contentDetails?.raw),
                optionsMainStyle
              )}
        </Styled.MigrationPanelInnerContainer>
  );
};

export default HomePageContent;
