import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import constants from '../../utils/utils';
import * as Styled from './homePageTemplateStyle';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import PromoBanner from '../../components/promoBanner/promoBanner';
import { Row, Col, Container } from 'react-bootstrap';
import HomeBottom from '../../components/homeBottom/homeBottom';
import HomePageContent from '../../components/homePageContent/homePageContent'
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import AccountTemplate from '../../components/navigationBar/accountTemplate/accountTemplate';
import Footer from '../../components/footer/footer';
import { Helmet } from 'react-helmet';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import Favicon from '../../images/favicon.png';
const templateAccount = [
  {
    accountTypeColor: '#00483A',
    anchorBGColor: '#00483A',
    anchorHoverColor: '#00382a',
    isLeft: true,
  },
  {
    accountTypeColor: '#00483A',
    anchorBGColor: '#ffc423',
    anchorHoverColor: '#D4A00F',
  },
];
const HomePage = () => {
  const pageData = useStaticQuery(graphql`
  query myHomePageQuery {
    allContentfulFasGasPlus(
      filter: {identifier: {type: {eq: "homePage"}}, node_locale: {eq: "en-US"}}
    ) {
      edges {
        node {
          title
          slug
          metadata
          node_locale
          sections {
            ... on ContentfulHomeContainers {
              id
              title
              type
              homecontainers {
                type
                title
                label
                textWithLink1 {
                  ... on ContentfulTextWithPath {
                    id
                    path
                    type
                    text
                  }
                }
                backgroundImages {
                  file {
                    url
                    fileName
                  }
                }
                textWithLink2 {
                  ... on ContentfulTextWithPath {
                    id
                    type
                    text
                    path
                  }
                }
                textWithLink3 {
                  ... on ContentfulTextWithPath {
                    id
                    path
                    type
                    text
                  }
                }
              }
            }
            ... on ContentfulListOfImageWithPath {
              id
              title
              type
              listOfImagesWithPath {
                path
                title
                type
                richText {
                  raw
                }
                image {
                  title
                  file {
                    fileName
                    url
                  }
                }
              }
            }
            ... on ContentfulContent {
              id
              title
              type
              contentDetails {
                raw
              }
            }
          }
        }
      }
    }
  }  
  `);
  const sections = pageData.allContentfulFasGasPlus.edges[0].node.sections;
  const accountTemplateData = sections?.filter(
    (section) => section.type === constants.HOME.HOME_CONTAINERS
  )[0];
  const HomeBottomData = sections?.filter(
    (section) => section.type === constants.HOME.HOME_LOCATIONS
  )[0];

  const metaData = pageData.allContentfulFasGasPlus.edges[0].node;
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>
          {metaData?.title 
          ? metaData?.title  + ' | Gas Station & Convienience Store'
          : 'Fas Gas Plus | Gas Station & Convienience Store'}
          </title>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content={metaData.title}></meta>
        <meta property="og:description" content={metaData.metadata}></meta>
        <meta property="og:image" content={Favicon}></meta>
        <meta property="og:url" content={process.env.GATSBY_DOMAIN_URL}></meta>
        <meta name="twitter:title" content={metaData.title}></meta>
        <meta name="twitter:description" content={metaData.metadata}></meta>
        <meta name="twitter:image" content={Favicon}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>
    <Styled.HomePageContainer>
      <DefaultHeaderMenu />
      <BootstrapNavbar />
      <AccountTemplate
        {...accountTemplateData?.homecontainers.filter(
          (section) =>
            section?.type === constants?.HOME?.HOME_JOURNIE_SECTION
        )[0]}
        {...templateAccount[0]}
      />
      <Styled.BottomContainer>
        <HomeBottom sectionData={HomeBottomData} />
        {/* <HomePageContent
          section={
            sections?.filter(
              (section) =>
                section.type === constants.HOME.HOME_RESIDENTIAL_CUSTOMERS
            )[0]
          }
        /> */}
      </Styled.BottomContainer>
      <BackToTopScroll/>
      <Footer />
    </Styled.HomePageContainer>
    </>
  );
};
export default HomePage;
